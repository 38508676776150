// import MicroModal from 'micromodal';  // es6 module
// /// <reference types="jquery"/>
// import "./public/stripe";
import "./public/multistepform";
// import { createPaymentIntent, mountCardElement, confirmCardPayment } from "./public/stripe";
import WPRequest from "./wp-request";

window.addEventListener("load", (event) => {
  /*
    ========================================================
    SnackBar
    ========================================================
  */
  const fcair_snackbar = document.querySelector("#fcair_snackbar");

  function fcair_showSnackBar(textToDisplay = "Copied to Clipboard") {
    var sb = document.getElementById("fcair_snackbar") as HTMLDivElement;

    if (sb == null || !sb) {
      console.log("fcair_snackbar not found");
      return;
    }

    sb.textContent = textToDisplay;

    //this is where the class name will be added & removed to activate the css
    sb.className = "show";

    setTimeout(() => {
      sb.className = sb.className.replace("show", "");
    }, 3000);
  }

  /*
    ========================================================
    modal code
    ========================================================
  */

  // Get the modal
  var modal = document.getElementById("fcairReportsModal") as HTMLDivElement;

  // Get the button that opens the modal
  var btn = document.getElementById("fcairGetReportBtn") as HTMLButtonElement;

  // Get the <span> element that closes the modal
  var closeBtn = document.getElementsByClassName("close")[0] as HTMLSpanElement;

  // When the user clicks on the button, open the modal
  btn.onclick = async function () {
    modal.classList.add("show");
    // await initStripePayment();
    // await initTestButton();

  };

  // When the user clicks on <span> (x), close the modal
  closeBtn.onclick = function () {
    modal.classList.remove("show");
  };

  // When the user clicks anywhere outside of the modal, close it
  window.onclick = function (event) {
    if (event.target == modal) {
      modal.classList.add("show");
    }
  };



});

/*
========================================================
    Main - Procedural Code
========================================================
*/
// When document loads...

// Path: js/src/public.ts

// let number: number;
// number = 1;
// console.log(number);
