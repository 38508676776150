import WPRequest from "../../wp-request";

interface PersonalData {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string | undefined;
}

function PersonalInfoFormStep() {
    this.form = document.querySelector("#fcair_form_personal_info form") as HTMLFormElement;

    this.firstName = document.querySelector("#fcair_form_personal_info form #firstName");

    this.lastName = document.querySelector("#fcair_form_personal_info form #lastName");

    this.email = document.querySelector("#fcair_form_personal_info form #email");

    this.phoneNumber = document.querySelector("#fcair_form_personal_info form #phoneNumber");

    this.submitBtn = document.querySelector("#btn_next");
}

/**
 * Check if the form is ready for submission
 * @returns {boolean}
 */
PersonalInfoFormStep.prototype.isReady = function (): boolean {
    if (this.form.checkValidity() === false) {
        this.form.reportValidity();
        return false;
    }else {
        return true;
    }
};

/**
 * Simulate form submission
 */
PersonalInfoFormStep.prototype.submit = function (): PersonalData{
    const formData: PersonalData = {
        firstName: this.firstName.value,
        lastName: this.lastName.value,
        email: this.email.value,
        phoneNumber: this.phoneNumber.value,
    };
    return formData;
};

export {PersonalInfoFormStep, PersonalData};
