export function toggleSpinnerForStep(
  step: number,
  show: boolean | null = null,
  innerText: string | null = null
) {
  let selector = ".form_step_" + step;
  const spinner = jQuery(selector).find(".fcair_loader");
  if (show === true) {
    spinner.addClass("show");
  } else if (show === false) {
    spinner.removeClass("show");
  } else {
    // Is Null - Toggle
    spinner.toggleClass("show");
  }
  const text = jQuery(selector).find(".fcair_loader_text");
  if (innerText && show) {
    text.text(innerText);
    text.addClass("show");
  } else {
    text.removeClass("show");
  }
}
