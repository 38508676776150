
import Toastify from "toastify-js";
import "toastify-js/src/toastify.css";

type toastifyAlign = "left" | "center" | "right";

function showToast(text: string, success: boolean = true, align: toastifyAlign = "center") {
  const bgColor = success ? "#0051C3" : "#ff0f0f";
  Toastify({
    text: text,
    duration: 3000,
    newWindow: true,
    close: true,
    gravity: "bottom", // `top` or `bottom`
    position: align, // `left`, `center` or `right`
    stopOnFocus: true, // Prevents dismissing of toast on hover
    style: {
      background: bgColor,
    },
    onClick: function () {}, // Callback after click
  }).showToast();
}

export {showToast};