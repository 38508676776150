 interface BusinessData {
    companyName: string;
    country: string;
    state: string;
    city: string;
    vertical: string;
    model: string;
    goals: string;
}

function BusinessInfoForm() {
    this.form = document.querySelector("#fcair_form_business_info form") as HTMLFormElement;
    this.companyName = document.querySelector("#fcair_form_business_info form #companyName");
    this.country = document.querySelector("#fcair_form_business_info form #country");
    this.state = document.querySelector("#fcair_form_business_info form #state");
    this.city = document.querySelector("#fcair_form_business_info form #city");
    this.vertical = document.querySelector("#fcair_form_business_info form #businessVertical");
    this.model = document.querySelector("#fcair_form_business_info form #businessModel");
    this.goals = document.querySelector("#fcair_form_business_info form #goals");

    this.submitBtn = document.querySelector("#btn_next");
}

/**
 * Check if the form is ready for submission
 * @returns {boolean}
 */
BusinessInfoForm.prototype.isReady = function (): boolean {
    if (this.form.checkValidity() === false) {
        this.form.reportValidity();
        return false;
    }else {
        return true;
    }
};

/**
 * Simulate form submission
 */
BusinessInfoForm.prototype.submit = function (): BusinessData{
    const formData: BusinessData = {
        companyName: this.companyName.value,
        country: this.country.value,
        state: this.state.value,
        city: this.city.value,
        vertical: this.vertical.value,
        model: this.model.value,
        goals: this.goals.value,
    };
    return formData;
};

export {BusinessInfoForm, BusinessData};
