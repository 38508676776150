import axios from 'axios';

type WPRequestData = {
    action: string;
    nonce: string;
    data: any;
}
type WPRequestResponse = {
    success: boolean;
    data?: any;
    message?: string;
}
class WPRequest {
  /**
   * Get data by wp hook
   */
  public static async hook(action = '', data = {}): Promise<WPRequestResponse | string> {
    try {
        const res = await axios.post(fcair_publicjs.ajax_url, this.setFormData({
            ...data,
            action: action,
            nonce: fcair_publicjs.nonce
          }));
      
          return res.data;
    } catch (error) {
        throw new Error(error);
    }
  }

  /**
   * Set Form Data
   */
  private static setFormData(data = {}): FormData {
    const formData = new FormData();
    const items = Object.keys(data);

    if (items.length) {
      items.forEach((item) => {
        if (data[item] instanceof Object || data[item] instanceof Array) {
          formData.append(item, JSON.stringify(data[item]));
        } else {
          formData.append(item, data[item]);
        }
      });
    }

    return formData;
  }
}

export default WPRequest;
