import {
  createPaymentIntent,
  mountCardElement,
  confirmCardPayment,
} from "../stripe";
import { showToast } from "../toast";
import { toggleSpinnerForStep } from "./toggle-step";
import { toggleErrorForStep } from "./error-step";

interface PaymentIntentData {
  firstName: string;
  lastName: string;
  email: string;
  companyName: string;
}

function StripeFormStep() {
  this.cardElement = null;
  this.paymentIntent = null;
  this.ready = false;
  this.prevButton = document.getElementById("btn_prev") as HTMLButtonElement;
  this.nextButton = document.getElementById("btn_next") as HTMLButtonElement;
}

StripeFormStep.prototype.init = async function (pIData: PaymentIntentData) {
  try {
    toggleSpinnerForStep(4, true, "Loading payment gateway...");
    this.prevButton.disabled = true;
    this.nextButton.disabled = true;

    this.paymentIntent = await createPaymentIntent(
      pIData.firstName,
      pIData.lastName,
      pIData.email,
      pIData.companyName
    );
    this.cardElement = mountCardElement(this.paymentIntent.client_secret);
    this.prevButton.disabled = false;
    toggleSpinnerForStep(4, false); 

    this.cardElement.on("change", (event) => {
    toggleSpinnerForStep(4, false); 
      if (event.complete) {
        this.ready = true;
        this.nextButton.disabled = false;
      } else if (event.error) {
        toggleErrorForStep(4, true, 'There\'s been an error with the payment gateway, please again later.');
        showToast(event.error.message as string);
      }
    });
  } catch (error) {
    toggleErrorForStep(4, true, 'There\'s been an error with the payment gateway, please again later.');
    toggleSpinnerForStep(4, false);
    showToast(error.message);
  }
};

/**
 * Check if the form is ready for submission
 * @returns {boolean}
 */
StripeFormStep.prototype.isReady = function (): boolean {
  return this.ready;
};

/**
 * Simulate form submission
 */
StripeFormStep.prototype.submit = async function () {
  try {
    if (!this.ready) {
      return;
    }
    toggleSpinnerForStep(
      4,
      true,
      "Processing payment, don/'t close the modal..."
    );
    const result = await confirmCardPayment(
      this.paymentIntent.client_secret,
      this.cardElement
    );
    if (result.error) {
      showToast(result.error.message as string);
      return { paymentIntentId: false };
    } else {
      toggleSpinnerForStep(4, false);
      // The payment has succeeded. Display a success message.
      if (result.paymentIntent.status === "succeeded") {
        return { paymentIntentId: result.paymentIntent.id };
      } else {
        toggleErrorForStep(4, true, 'There\'s been an error with the payment gateway, please again later.');
        showToast("Payment failed, please try again later.");
        return { paymentIntentId: false };
      }
    }
  } catch (e) {
    toggleSpinnerForStep(4, false);
    toggleErrorForStep(4, true, 'There\'s been an error when processing the payment, please try again later.');
    showToast(e.message as string);
    return { paymentIntentId: false };
  }
};

export { StripeFormStep, PaymentIntentData };
