import WPRequest from "../../wp-request";
import { showToast } from "../toast";
import { BusinessData } from "./business-info-form-step";
import { toggleSpinnerForStep } from "./toggle-step";
import { toggleErrorForStep } from "./error-step";

export function ShowPreviewStep(businessData: BusinessData) {
  this.previewParagraph = document.querySelector(
    "#fcair_show_preview #preview"
  ) as HTMLParagraphElement;
  this.prevButton = document.getElementById("btn_prev") as HTMLButtonElement;
  this.nextButton = document.getElementById("btn_next") as HTMLButtonElement;
  this.businessData = businessData;
  this.preview = {};
  this.ready = false;
}

ShowPreviewStep.prototype.fetchPreview = async function () {
  toggleSpinnerForStep(3, true, "AI is thinking...");
  this.prevButton.disabled = true;
  this.nextButton.disabled = true;
  const response = await WPRequest.hook(
    "fcair_create_report_preview",
    this.businessData
  );
  toggleSpinnerForStep(3, false);
  if (response?.success) {
    this.previewParagraph.innerHTML = response?.data;
    this.preview = response?.data;
    this.prevButton.disabled = false;
    this.nextButton.disabled = false;
    this.ready = true;
  } else {
    toggleErrorForStep(3, true, "There's been an error when fetching the preview");
    showToast("Error, please try again later.");
  }

};

ShowPreviewStep.prototype.isReady = function (): boolean {
  return this.ready;
};

ShowPreviewStep.prototype.submit = function () {
  return { preview: this.preview };
};
