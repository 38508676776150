import WPRequest from "../../wp-request";
import { BusinessData } from "./business-info-form-step";
import { PersonalData } from "./personal-info-form-step";
import { toggleSpinnerForStep } from "./toggle-step";
import { toggleErrorForStep } from "./error-step";
import { showToast } from "../toast";

type AllUserInput = BusinessData &
  PersonalData & {
    preview: string;
    paymentIntentId: string;
  };

function FinalStep(allUserInput: AllUserInput) {
  this.ready = false;
  this.allUserInput = allUserInput;
}

FinalStep.prototype.submit = async function () {
  toggleSpinnerForStep(4, true, "Submitting report...");
  const result = await WPRequest.hook(
    "fcair_submit_report_form",
    this.allUserInput
  );
  if (result.success) {
    this.ready = true;
  }else{
    toggleErrorForStep(4, true, "There's been an error when submitting the report. Contact the administrator.");
    showToast("There's an error when requesting the report.");
  }
  toggleSpinnerForStep(4, false);

  return result;
};

FinalStep.prototype.isReady = function (): boolean {
  return this.ready;
};

export { FinalStep, AllUserInput };
