import WPRequest from "../wp-request";
import { loadStripe } from "@stripe/stripe-js";

let stripe: any = null

async function loadStripeSdk() {
  stripe = await loadStripe(
    fcair_publicjs.stripe_public_key 
  );
}

loadStripeSdk();

export function mountCardElement(clientSecret) {
  const elements = stripe.elements({
    clientSecret: clientSecret,
  });
  const cardElement = elements.create("card");
  cardElement.mount("#card-element");
  return cardElement;
}

export async function createPaymentIntent(
  firstName: string,
  lastName: string,
  email: string,
  companyName: string
): Promise<any> {
  const result = await WPRequest.hook("fcair_create_payment_intent", {
    firstName,
    lastName,
    email,
    companyName,
  });
  console.log({ result })
  if (result.success) {
    return result.data;
  } else {
    throw new Error(result.data);
  }
}

export function confirmCardPayment(clientSecret, cardElement) {
  return stripe.confirmCardPayment(clientSecret, {
    payment_method: {
      card: cardElement,
    },
  });
}
