/// <reference types="jquery"/>

import { PersonalInfoFormStep } from "./multistepform-steps/personal-info-form-step";
import {
  BusinessData,
  BusinessInfoForm,
} from "./multistepform-steps/business-info-form-step";
import { ShowPreviewStep } from "./multistepform-steps/show-preview-step";
import {
  PaymentIntentData,
  StripeFormStep,
} from "./multistepform-steps/stripe-form-step";
import { FinalStep, AllUserInput } from "./multistepform-steps/final-step";
import { showToast } from "./toast";
 
jQuery(document).ready(function () {
  var active = 1;
  var steps = 5;
  var activeForm: any = null;
  var formResult = {};
  updateStep();

  function onlyShowUsableButtons() {
    if (active == 1) {
      jQuery("#btn_prev").hide();
    } else {
      jQuery("#btn_prev").show();
    }
    if (active == steps) {
      jQuery("#btn_next").hide();
      jQuery("#btn_prev").hide();
    } else {
      jQuery("#btn_next").show();
    }
  }

  function updateProgress() {
    jQuery(".barStep").each(function (index) {
      if (index + 1 < active) {
        jQuery(this).addClass("active");
      } else {
        jQuery(this).removeClass("active");
      }
    });
    adjustProgressBarWidth();
  }

  function adjustProgressBarWidth() {
    const barStepWidthPx = 42;
    let progressPercent = ((active - 1) / (steps - 1)) * 100 + "%";
    let nodesWidth = (active - 1) * barStepWidthPx + "px";

    jQuery("#progress-bar").css(
      "width",
      "calc(" + progressPercent + " + " + nodesWidth + ")"
    );
  }

  function showCurrentStep() {
    let selector = ".form_step_" + active;
    jQuery(".form_step").each(function (index) {
      jQuery(this).removeClass("show");
    });
    jQuery(selector).addClass("show");
  }

  

  function updateStep() {
    onlyShowUsableButtons();
    updateProgress();
    showCurrentStep();
    if (active === 1) {
      activeForm = new PersonalInfoFormStep();
    }
    if (active === 2) {
      activeForm = new BusinessInfoForm();
    }
    if (active === 3) {
      if (formResult != null && !formResult.hasOwnProperty("preview")) {
        async function fetchPreview() {
          const businessData: BusinessData = formResult as BusinessData;
          const showPreviewStep = new ShowPreviewStep(businessData);
          activeForm = showPreviewStep;
          await showPreviewStep.fetchPreview();
        }
        fetchPreview();
      }
    }
    if (active === 4) {
      if (formResult != null && !formResult.hasOwnProperty("paymentIntentId")) {
        activeForm = new StripeFormStep();
        async function initStripeForm() {
          await activeForm.init(formResult as PaymentIntentData);
        }
        initStripeForm();
      }
    }
  }

  jQuery("#btn_prev").click(function (e) {
    e.preventDefault();
    active--;
    if (active < 1) {
      active = 1;
      return;
    }
    updateStep();
  });

  jQuery("#btn_next").click(function (e) {
    e.preventDefault();
    if (activeForm != null) {
      if (!activeForm.isReady()) {
        return;
      }
      if (active === 4) {
        async function submitFinal() {
          const stripePaymentResult = await activeForm.submit();
          if (
            stripePaymentResult.hasOwnProperty("paymentIntentId") &&
            stripePaymentResult.paymentIntentId === false
          ) {
            return;
          }
          formResult = { ...formResult, ...stripePaymentResult };
          const final = new FinalStep(formResult as AllUserInput);
          let submitResults = await final.submit();
          if (submitResults.success) {
            showToast("Report requested successfully!", true);
          } else {
            showToast("Error requesting report. Please try again later.", false);
            return
          }
        }
        submitFinal();
      } else {
        formResult = { ...formResult, ...activeForm.submit() };
      }
    }
    active++;
    if (active > steps) {
      active = steps;
      return;
    }
    updateStep();
  });

  jQuery("select#is-title-open").change(function (e) {
    e.preventDefault();
    if (jQuery("select#is-title-open").val() == "No") {
      jQuery(".open-title-questions").slideUp();
    } else {
      jQuery(".open-title-questions").slideDown();
    }
  });

  // Displaying hidden fields

  let specificTypeOfPropertySections = [
    "commercial_fields",
    "multifamily_fields",
    "land_fields",
  ];

  // Do it as soon as the page loads
  showOnlyRightPropTypeFields(
    jQuery("input[name='type-of-property']:checked").val()
  );

  jQuery("input[name='type-of-property']").click(function () {
    let typePropertyValue = jQuery(
      "input[name='type-of-property']:checked"
    ).val();
    showOnlyRightPropTypeFields(typePropertyValue);
  });

  function cleanUpPropTypeValue(typePropertyValue) {
    let newVal = typePropertyValue.replace("-", "").toLowerCase();
    return newVal;
  }

  function showOnlyRightPropTypeFields(typePropertyValue) {
    let divToShow = cleanUpPropTypeValue(typePropertyValue) + "_fields";
    console.log("Trying to show " + divToShow);
    specificTypeOfPropertySections.forEach(function (divId) {
      if (divId === divToShow) {
        console.log("Showing " + divToShow);
        jQuery("#" + divId).removeClass("d-none");
      } else {
        jQuery("#" + divId).addClass("d-none");
      }
    });
  }
});
